import {Head} from "../lib/Head";
import React from "react";
import {View} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Paragraph, Heading} from "@ch/foundations/ui";
import {Image} from "../lib/Image";
import {TopNav} from "../components";
import AppointmentsLg from "../../public/static/images/Appointments-lg.png";

export default function Custom404() {
  return (
    <View
      accessibilityRole="main"
      testID="404-page"
      style={tailwind("w-full h-full flex-col  items-center")}
    >
      <Head>
        <title>Carbon Enterprise | Page Not Found</title>
      </Head>
      <TopNav />
      <View style={tailwind("w-full flex-col justify-center items-center")}>
        <Image src={AppointmentsLg} alt="A person standing by a bike checking their phone" />
        <Heading
          weight="bold"
          accessibilityRole="heading"
          level={6}
          accessibilityLevel={1}
          style={tailwind("mt-8")}
        >
          Oops! Looks like you’re lost.
        </Heading>
        <Paragraph style={tailwind("mt-5")}>
          {"The page you’re looking for wasn’t found."}
        </Paragraph>
      </View>
    </View>
  );
}
