import {View} from "react-native";
import {CarbonLogoText, Icon} from "@ch/foundations/ui";
import {tailwind} from "../../lib/foundations/styles";
import {Link} from "../../lib/Link";

export default function TopNav() {
  return (
    <View
      accessibilityRole="navigation"
      style={tailwind(
        "flex w-full flex-row items-center justify-start px-8 py-7 shadow-size-s shadow bg-white",
      )}
    >
      <Link
        href="/"
        accessibilityLabel="Dashboard Home"
        style={tailwind("flex flex-row items-center")}
      >
        <Icon name="carbon" size={24} />
        <View style={tailwind("ml-3")}>
          <CarbonLogoText height={16} />
        </View>
      </Link>
    </View>
  );
}
